import React, { useLayoutEffect } from "react"
import Container from "../container/container"
import MainHeading from "../mainheading/mainheading"
import { BreadArrow } from "../icons"
import ReactHtmlParser from "react-html-parser"
import "./heronoimage.scss"

export default function HeroNoImage({
  heading = "Sentence case headline",
  subHeading = "",
  breadcrumb = [],
  description = "",
  component = null,
  className = "",
}) {
  useLayoutEffect(() => {
    const hashme = document.querySelectorAll(".hashme")
    hashme.forEach(item => {
      item.addEventListener("click", e => {
        e.preventDefault()

        const hash = item.getAttribute("data-hash")
        if (hash) {
          // window.location.hash = item.getAttribute("data-hash")
          const element = document.getElementById(hash)
          if (element) {
            // element.scrollIntoView(
            //   true
            // )
            var vw = Math.max(
              document.documentElement.clientWidth || 0,
              window.innerWidth || 0
            )

            var headerOffset = vw < 1361 ? 70 / 2 : 82 / 2
            var elementPosition = element.getBoundingClientRect().top
            var offsetPosition = elementPosition + window.scrollY - headerOffset

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            })
          }
        }
      })
    })
  }, [])

  return (
    <div className={`heropattern v2 ${className}`}>
      <Container className="hero-section-outer hero-no-image-outer pd-hero">
        <div className="hero-no-image-inner">
          <div
            className="breadcrumbs"
            style={
              {
                // minHeight: "16px",
              }
            }
          >
            {breadcrumb.map((item, index) => {
              return (
                <span key={index}>
                  <a href={item.url}>{item.name}</a>
                  <BreadArrow />
                </span>
              )
            })}
          </div>
          <div className="hero-wrapper column">
            <MainHeading
              heroSection={true}
              className="main-heading"
              subHeading={subHeading}
            >
              {heading}
            </MainHeading>
            {ReactHtmlParser(description)}

            {component}
          </div>
        </div>
      </Container>
    </div>
  )
}
